import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import routePath from "@/commons/RoutePath";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import DIContainer from "@/core/DIContainer";
import TablePreload from "@/presentation/components/preloader/table-preload/TablePreload.vue";
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import { useStore } from "vuex";
export default defineComponent({
    name: "selectOrganization",
    components: { TablePreload: TablePreload },
    props: ["organizationInfo", "breadcrumb"],
    setup: function (props) {
        var route = routePath;
        var router = useRouter();
        var store = useStore();
        var authInteractor = DIContainer.instance.get(AuthInteractor);
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var state = reactive({
            listMember: [],
            organizations: [],
            preload: true,
            showInvitePopup: false,
            showDeleteMemberPopup: false,
            showPopupAlert: false,
            popupTitle: '',
            popupContent: '',
            deleteMember: {}
        });
        function organizations() {
            organizationInteractor
                .selectOrganization()
                .then(function (result) {
                state.organizations = result.data;
                state.preload = false;
            })
                .catch(function (result) {
                state.preload = false;
            });
        }
        function organizationSelection(organization_id) {
            organizationInteractor
                .selectedOrganization(organization_id)
                .then(function (result) {
                return authInteractor.authCheck().then(function (result) {
                    if (result.data &&
                        result.data.user &&
                        (result.data.user.name ||
                            result.data.user.nick_name)) {
                        var account_name = result.data.user.name;
                        var is_composition = result.data.user.is_composition;
                        localStorage.setItem("accountName", account_name);
                        localStorage.setItem("isComposition", is_composition);
                        store.commit("setHeaderMode", "organizationLogged");
                        store.commit("setIsloginStatus", true);
                        router.push(routePath.ORGANIZATION_DASHBOARD);
                    }
                });
            })
                .catch(function (result) {
                router.push(routePath.ORGANIZATION_DASHBOARD);
            });
        }
        onMounted(function () {
            organizations();
        });
        return {
            state: state,
            props: props,
            organizationSelection: organizationSelection,
        };
    }
});
