<template>
    <div class="comSelectOrg">
        <div class="comSelectOrg__container">
            <div class="comSelectOrg__container-title">
              担当企業選択
            </div>
            <ul class="organizationLists">
                <li
                    class="organizationLists__item"
                    v-for="org in state.organizations"
                    :key="org.id"
                >
                    <p class="organizationLists__item-name">{{ org.name }}</p>
                    <button
                        class="organizationLists__item-button btn-xxs btn-outline-blue"
                        @click="organizationSelection(org.id)"
                    >
                        選択
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/select-organization/SelectOrganization.ts"></script>

<style lang="scss" scoped>
@import "@/presentation/views/organization/select-organization/SelectOrganization.scss";
</style>